import { Routes } from '@angular/router';
import { FcSoonComponent } from './shared/components';
import { noUserGuard } from './core/guards/no-user.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/persons/persons.routes').then((r) => r.routes),
  },
  {
    path: 'personal-loans',
    loadChildren: () =>
      import('./features/personal-loans/personal-loans.routes').then(
        (r) => r.routes
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.routes').then((r) => r.routes),
    canActivate: [noUserGuard],
  },
  {
    path: 'deposit',
    loadChildren: () =>
      import('./features/fixed-term-deposit/fixed-term-deposit.routes').then(
        (r) => r.routes
      ),
  },
  {
    path: 'pay-membership',
    loadChildren: () =>
      import('./features/pay-membership/pay-membership.routes').then(
        (r) => r.routes
      ),
  },
  {
    path: 'receive-money',
    component: FcSoonComponent,
  },
  {
    path: 'saving',
    component: FcSoonComponent,
  },
  {
    path: 'send-money',
    component: FcSoonComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
